<template>
  <Content>
    <template #contentTitle>{{ $t('menu.transit_label_change_list') }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between">
        <a-col>
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input
                style="width: 250px;"
                v-model:value="searchState.searchKey"
                :placeholder="$t('warehouse.label_task_name')+'/'+$t('warehouse.tag_task_number')+'/'+$t('warehouse.shipment_name')"
                :title="$t('warehouse.label_task_name')+'/'+$t('warehouse.tag_task_number')+'/'+$t('warehouse.shipment_name')"
                allow-clear
              ></a-input>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px;"
                v-model:value="searchState.labelTaskType"
                :placeholder="$t('warehouse.label_service_type')"
                allow-clear
              >
                <a-select-option
                  v-for="(item, index) in labelTaskTypeEnum"
                  :key="index"
                  :value="item"
                  :title="$t($enumLangkey('labelTaskType', item))"
                >{{ $t($enumLangkey('labelTaskType', item)) }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px;"
                v-model:value="searchState.warehouseId"
                :placeholder="$t('logistics.warehouse_code')"
                allow-clear
              >
                <a-select-option
                  v-for="(item, index) in warehouseList"
                  :key="index"
                  :value="item.id"
                  :title="item.warehouseNo + '('+ item.warehouseName +')'"
                >{{ item.warehouseNo + '('+ item.warehouseName +')' }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px;"
                v-model:value="searchState.status"
                :placeholder="$t('warehouse.task_status')"
                allow-clear
              >
                <a-select-option
                  v-for="(item, index) in labelTaskStatusEnum"
                  :key="index"
                  :value="item"
                  :title="$t($enumLangkey('labelTaskStatus', item))"
                >{{ $t($enumLangkey('labelTaskStatus', item)) }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.search') }}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="ghost">
            <router-link to="/transit/labelchange/create">{{ $t('common.create') }}</router-link>
          </a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        size="small"
        :columns="columns"
        :data-source="tableData.tableList"
        :scroll="{ x: true, y: wrap.contentHeight - 110 }"
        :pagination="false"
        :row-key="(record) => record.id"
        :loading="tableData.loading"
      >
        <template #labelTaskTitle>
          <div>{{ $t('warehouse.label_task_name') }}</div>
          <div>{{ $t('warehouse.tag_task_number') }}</div>
        </template>
        <template #shipmentInformationTitle>
          <div>{{ $t('warehouse.shipment_name') }}</div>
          <div>{{ $t('warehouse.se_shipment_number') }}</div>
        </template>
        <template #dateTitle>
          <div>{{ $t('warehouse.task_time') }}</div>
          <div>{{ $t('warehouse.update_time') }}</div>
        </template>
        <template #newShipmentNameTitle>
          <div>{{ $t('warehouse.change_the_shipment_name') }}</div>
          <div>{{ $t('warehouse.new_shipment_name') }}</div>
        </template>
        <template #warehouse="{ record }">
          {{ record.warehouse?.warehouseNo || '-' }}
        </template>
        <template #taskLabel="{ record }">
          <div>{{ record.taskName || '-' }}</div>
          <div>{{ record.taskNo || '-' }}</div>
        </template>
        <template #labelTaskType="{ record }">
          {{ $t($enumLangkey('labelTaskType', record.labelType)) }}
        </template>
        <template #shipmentInfo="{ record }">
          <div>{{ record.shipment?.shipmentName || '-' }}</div>
          <div>{{ record.shipment?.seShipmentNo || '-' }}</div>
        </template>
        <template #newShipmentName="{ record }">
          <div>
            <span v-if="record.isChangeShipmentName === true" class="text-success">{{ $t('common.yes') }}</span>
            <span v-else-if="record.isChangeShipmentName === false">{{ $t('common.no') }}</span>
            <span v-else>-</span>
          </div>
          <div>{{ record.newShipmentName || '-' }}</div>
        </template>
        <template #boxCount="{ record }">
          {{ record.boxCount || '-' }}
        </template>
        <template #shipmentBoxCount="{ record }">
          {{ record.shipmentBoxCount || '-' }}
        </template>
        <template #labelCount="{ record }">
          {{ record.labelCount || '-' }}
        </template>
        <template #skuCount="{ record }">
          {{ record.skuCount || '-' }}
        </template>
        <template #productCount="{ record }">
          {{ record.productCount || '-' }}
        </template>
        <template #status="{ record }">
          {{ $t($enumLangkey('labelTaskStatus', record.status)) }}
        </template>
        <template #dateCustomer="{ record }">
          <div>{{ $filters.utcToCurrentTime(record.creationTime) }}</div>
          <div>{{ $filters.utcToCurrentTime(record.lastModificationTime) }}</div>
        </template>
        <template #operation="{ record }">
          <a-dropdown-button @click="handleButtonClick(record)">
            <span v-if="record.status === labelTaskStatusEnum.planned">{{ $t('common.edit') }}</span>
            <span v-else>{{ $t('common.details') }}</span>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item key="1" :record="record" :disabled="record.status !== labelTaskStatusEnum.planned && record.status !== labelTaskStatusEnum.ordered">{{ $t('common.cancel') }}</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
    </template>
    <template #contentFooter>
      <CPager class="text-center" :page-data="tableData.pageData" @handlePage="handlePage"></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onActivated, onMounted, reactive, toRefs } from 'vue';
import { Button, Col, Dropdown, Input, Menu, Modal, Row, Select, Table, message } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CPager from '@/views/components/CPager.vue';
import { useI18n } from "vue-i18n/index";
import { useRouter } from 'vue-router';
import {
  labelTaskStatus as labelTaskStatusEnum,
  labelTaskType as labelTaskTypeEnum,
  warehouseType as warehouseTypeEnum,
} from '@/enum/enum.json';
import { getWarehouses } from '@/api/modules/common/index.js';
import { getTransitsLabelChangeTaskListPaged, cancelTransitsLabelChangeTask, } from '@/api/modules/transit/labelChange.js';

export default defineComponent({
  name: 'transit_label_change_list',
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();

    const state = reactive({
      searchState: {
        searchKey: null,
        labelTaskType: null,
        warehouseId: null,
        status: null,
        isNeedWarehouse: true,
        isNeedTransitsShipment: true,
      },
      searchStateCache: {},
      warehouseLoading: false,
      warehouseList: [],
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        width: 120,
        title: () => vueI18n.t('warehouse.warehouse_code'),
        slots: {
          customRender: "warehouse",
        }
      },
      {
        width: 200,
        slots: {
          title: "labelTaskTitle",
          customRender: "taskLabel",
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.label_service_type'),
        slots: {
          customRender: "labelTaskType",
        }
      },
      {
        width: 150,
        slots: {
          title: "shipmentInformationTitle",
          customRender: "shipmentInfo",
        }
      },
      {
        width: 150,
        slots: {
          title: "newShipmentNameTitle",
          customRender: "newShipmentName",
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.number_of_task_boxes'),
        slots: {
          customRender: "boxCount",
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.number_of_cargo_boxes'),
        slots: {
          customRender: "shipmentBoxCount",
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.number_of_labels_per_box'),
        slots: {
          customRender: "labelCount",
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.sku_number'),
        slots: {
          customRender: "skuCount",
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.product_quantity'),
        slots: {
          customRender: "productCount",
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.task_status'),
        slots: {
          customRender: "status",
        }
      },
      {
        width: 120,
        slots: {
          title: "dateTitle",
          customRender: "dateCustomer",
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('common.operation'),
        align: 'center',
        fixed: 'right',
        slots: {
          customRender: "operation",
        }
      },
    ]

    const handleButtonClick = (record) => {
      if (record.status === labelTaskStatusEnum.planned) {
        switch (record.userOperateStep) {
          case 1:
            router.push('/transit/labelchange/uploadtags/' + record.id);
            break;
          case 2:
            router.push('/transit/labelchange/confirm/' + record.id);
            break;
          default:
            break;
        }
      } else {
        router.push('/transit/labelchange/' + record.id);
      }
    }

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      switch (key) {
        case '1':
          // 取消
          handleCancel(record.id);
          break;
        default:
          break;
      }
    }

    const handleCancel = (id) => {
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("common.are_you_sure_cancel"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return cancelTransitsLabelChangeTask({ id }).then(() => {
            message.success(vueI18n.t("common.successfully_canceled"));
            getPageList();
          });
        },
      });
    }

    const getPageList = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      if (data.warehouseId) {
        data.warehouseIds = [data.warehouseId];
      }
      delete data.warehouseId;
      getTransitsLabelChangeTaskListPaged(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0
      state.tableData.pageData.currentIndex = 1
      getPageList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitPage();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    const getWarehouseList = () => {
      state.warehouseLoading = true;
      const data = {
        warehouseType: warehouseTypeEnum.transitWarehouse,
        isActive: true,
      }
      getWarehouses(data).then(({ result }) => {
        if (Array.isArray(result)) {
          state.warehouseList = result;
        } else {
          state.warehouseList = [];
        }
      }).catch(() => {}).finally(() => {
        state.warehouseLoading = false;
      })
    };

    onActivated(handleSearch);

    onMounted(getWarehouseList);

    return {
      ...toRefs(state),
      labelTaskStatusEnum,
      labelTaskTypeEnum,
      columns,
      handleButtonClick,
      handleMenuClick,
      handleSearch,
      handlePage,
    }
  }
})
</script>

<style scoped></style>